<template>
  <vx-card>
    <vx-form
      v-slot="{ can, loading }"
      :resource="formResource"
    >
      <vx-input
        v-model="formValue.name"
        disabled
        name="name"
        label="Name"
      />

      <vx-input
        v-model="formValue.order"
        disabled
        name="order"
        label="Order"
      />

      <vx-select
        v-model="formValue.type"
        :options="typeOptions"
        :clearable="false"
        name="type"
        label="Payment type"
      />

      <b-col>
        <b-row>
          <vx-checkbox
            v-model="formValue.is_active"
            name="is_active"
            label="Is Active"
            class="mr-1"
          />
          <vx-checkbox
            v-model="formValue.is_visible"
            name="is_visible"
            label="Is Visible"
          />
        </b-row>
      </b-col>

      <vx-input
        v-model="formValue.min_amount"
        :rules="rules.min_amount"
        type="number"
        name="min_amount"
        step="1"
        min="0"
        label="Min Amount"
      />

      <vx-input
        v-model="formValue.max_amount"
        :rules="rules.max_amount"
        type="number"
        name="max_amount"
        step="1"
        min="0"
        label="Max Amount"
      />

      <vx-input
        v-model="formValue.suggested_amount"
        :rules="rules.suggested_amount"
        type="number"
        name="suggested_amount"
        step="1"
        min="0"
        :disabled="isSkinsType"
        label="Suggested Amount"
      />

      <vx-input
        v-model="formValue.new_player_max_amount"
        :rules="rules.new_player_max_amount"
        type="number"
        name="new_player_max_amount"
        step="1"
        min="0"
        :disabled="isSkinsType"
        label="New Player Max Amount"
      />

      <vx-select
        v-model="formValue.suggested_amount_list"
        :rules="rules.suggested_amount_list"
        :options="[]"
        name="suggested_amount_list"
        label="Suggested Amount List"
        multiple
        deselect-from-dropdown
        taggable
      />

      <vx-button
        :loading="loading"
        :can="can"
        variant="primary"
        type="submit"
      >
        Save
      </vx-button>
    </vx-form>
  </vx-card>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { computed, onBeforeMount, reactive, watch } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'
import VxCard from '@/components/VxCard'
import { VxForm, VxInput, VxSelect, VxCheckbox } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { getRouteIdParam } from '@/router'
import { passDataToResource, paymentSystems } from '@/services/resources'
import { formatToCents, formatToDecimal } from '@/services/utils'
import { pick } from 'lodash'

const initialForm = {
  name: '',
  type: '',
  money_type: '',
  is_active: false,
  is_visible: false,
  min_amount: null,
  max_amount: null,
  suggested_amount: null,
  suggested_amount_list: [],
  new_player_max_amount: null,
  order: 0
}

export default {
  name: 'PaymentSettingsEditor',
  components: {
    VxCard,
    VxForm,
    VxInput,
    VxSelect,
    VxCheckbox,
    VxButton,
    BCol,
    BRow
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup ({ dataResolverResponse }) {
    const formValue = reactive({ ...initialForm })

    const types = {
      any: 'any',
      card: 'card',
      skins: 'skins'
    }
    const typeOptions = Object.values(types)

    const isSkinsType = computed(() => formValue.type === types.skins)

    const frontToBackMapper = (values) => {
      const suggested_amount_list = values.suggested_amount_list.length
        ? values.suggested_amount_list.map((value) => Number(formatToCents(value)))
        : null

      const {
        min_amount,
        max_amount,
        suggested_amount,
        new_player_max_amount
      } = values

      return {
        ...values,
        min_amount: min_amount && formatToCents(min_amount),
        max_amount: max_amount && formatToCents(max_amount),
        suggested_amount: suggested_amount && formatToCents(suggested_amount),
        new_player_max_amount: new_player_max_amount && formatToCents(new_player_max_amount),
        suggested_amount_list
      }
    }

    const backToFrontMapper = ({
      min_amount,
      max_amount,
      suggested_amount,
      new_player_max_amount,
      suggested_amount_list,
      ...rest
    }) => {
      return {
        ...rest,
        min_amount: min_amount && formatToDecimal(min_amount),
        max_amount: max_amount && formatToDecimal(max_amount),
        suggested_amount: suggested_amount && formatToDecimal(suggested_amount),
        new_player_max_amount: new_player_max_amount && formatToDecimal(new_player_max_amount),
        suggested_amount_list: (suggested_amount_list || []).map(formatToDecimal)
      }
    }

    const formResource = passDataToResource(paymentSystems.updateGateway, {
      frontToBackMapper,
      requestParams: {
        urlParams: { id: getRouteIdParam() }
      }
    })

    const amountRules = {
      integer: true,
      nullable_or_min_value: 1
    }

    const rules = {
      min_amount: {
        ...amountRules,
        max_value: 1000
      },
      max_amount: {
        ...amountRules,
        max_value: 10000
      },
      suggested_amount: {
        ...amountRules,
        max_value: 1000
      },
      new_player_max_amount: {
        ...amountRules,
        max_value: 10000
      },
      suggested_amount_list: {
        integer_array: true,
        min_length_array: 1,
        max_item_value: 1000,
        min_item_value: 1
      }
    }

    onBeforeMount(() => setValuesToForm(backToFrontMapper(dataResolverResponse), formValue))

    watch(() => isSkinsType.value, (value) => {
      if (value) {
        const fields = ['max_amount', 'new_player_max_amount', 'suggested_amount']
        const payload = {
          ...formValue,
          ...pick(initialForm, fields)
        }

        setValuesToForm(payload, formValue)
      }
    })

    return {
      formResource,
      formValue,
      rules,

      isSkinsType,
      typeOptions
    }
  }
}
</script>
